import { CSSProperties, ReactNode } from 'react'
import classnames from 'classnames'
import { IconTypes } from '~ui/Icon'
import { TextCol, Grid, Flex, Image, Heading, Icon, Link } from '~ui'
import { Fields } from '~ui/TextCol'

import styles from './WidgetItem.module.scss'

export type ItemProps = {
  fields: Fields[]
  imageSrc?: string
  iconForMobile?: IconTypes
  link?: string
  date?: string[]
  eventSlug?: string
  onlyVip?: boolean
  external?: boolean
  divider?: boolean
  actions: Array<ReactNode>
  isMobile?: boolean
  isEvent?: boolean
  sellingFast?: boolean
}

const SellingFastLabel = () => (
  <Heading Tag="span" colorGlow="red" className={styles['selling-fast-label']}>
    <Icon
      name="lightning-v2"
      glowColor="red"
      style={{
        width: '1em',
        height: '1em',
      }}
      className={styles.icon}
    />
    This event is selling fast
  </Heading>
)

const Item: React.FC<ItemProps> = ({
  fields,
  imageSrc,
  link,
  divider,
  date,
  onlyVip,
  isMobile,
  isEvent = true,
  sellingFast,
}) => {
  const imageAlt = fields && fields[0] && fields[0][0]?.text
  const isImage = !!imageSrc
  const fieldsLen = fields && fields.length

  return (
    <Flex flexDirection="column" width="100%" className={classnames(styles.cell)}>
      {isMobile ? (
        <>
          <Link
            uppercase={false}
            classes={classnames(styles['cell-button'], styles['cell-mobile'])}
            target="_blank"
            href={link}
          >
            <div
              className={classnames(styles.item, styles['item-mobile'])}
              style={
                {
                  '--template': `${isImage ? 'auto' : ''} ${fieldsLen < 2 ? '1fr' : '125px 1fr'} ${
                    !isImage ? 'auto' : ''
                  }`,
                } as CSSProperties
              }
            >
              {isImage && (
                <Image
                  wrapperClassName={styles.image}
                  src={imageSrc}
                  alt={imageAlt}
                  height={75}
                  width={75}
                />
              )}
              {date && (
                <div className={styles['date-label']}>
                  <Heading Tag="span" uppercase color="light-gray">
                    {date[0]}
                  </Heading>
                  <Heading Tag="span" className={styles.middle} uppercase>
                    {date[1]}
                  </Heading>
                  <Heading Tag="span" uppercase color="light-gray">
                    {date[2]}
                  </Heading>
                </div>
              )}
              <Grid className={styles['text-fields']}>
                {sellingFast && <SellingFastLabel />}
                {fields &&
                  fields.map((field, key) => (
                    <TextCol
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${key}-${field && field[0].text}`}
                      fields={field}
                    />
                  ))}
                {isEvent && (
                  <Heading Tag="span" colorGlow="g-green-darker">
                    <Grid gridAutoFlow="column" gap="8px" width="fit-content" placeItems="center">
                      <Icon
                        name="ticket-thin"
                        glowColor="g-green-darker"
                        width="1.1em"
                        height="1.1em"
                      />
                      {onlyVip ? 'View Packages' : 'Get Tickets'}
                    </Grid>
                  </Heading>
                )}
              </Grid>
            </div>
          </Link>
        </>
      ) : (
        <>
          <Link href={link} uppercase={false} target="_blank" classes={styles['link-style']}>
            <div className={styles.item}>
              <div>
                <div
                  style={{
                    paddingBottom: 12,
                  }}
                >
                  {sellingFast && <SellingFastLabel />}
                </div>
                <div
                  className={styles.item}
                  style={
                    {
                      '--template': `${isImage ? '100px' : ''} ${
                        fieldsLen < 2 ? '1fr' : '130px auto'
                      } auto`,
                      padding: 0,
                    } as CSSProperties
                  }
                >
                  {isImage && (
                    <Image
                      wrapperClassName={styles.image}
                      src={imageSrc}
                      alt={imageAlt}
                      height={100}
                      width={100}
                    />
                  )}
                  {fields &&
                    fields.map((field, key) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <TextCol key={`${key}-${field && field[0].text}`} fields={field} />
                    ))}
                </div>
              </div>
            </div>
          </Link>
        </>
      )}
      {divider && <div className={styles.separator} />}
    </Flex>
  )
}

export default Item
