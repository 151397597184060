import { FC } from 'react'

import { Typography } from '~ui'
import styles from './NoData.module.scss'

const { Text } = Typography

const NoData: FC<{ description?: string }> = ({ description }) => (
  <div className={styles.wrapper}>
    <img src="https://img.granted.co/1666611743548-no-data.png" alt="no data" width={420} />

    <Text center size="body" lineHeight="body" color="green">
      {description || 'No data to display'}
    </Text>
  </div>
)

export default NoData
