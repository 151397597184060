import { Placeholder } from '~ui'

import styles from './ItemsList.module.scss'

const EventsListPlaceholder = ({ itemsCount = 10 }) => (
  <>
    {Array(itemsCount)
      .fill(null)
      .map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i} className={styles['placeholder-list-item']}>
          <Placeholder style={{ height: 18, width: '100%' }} />
          <Placeholder style={{ height: 18, width: '80%' }} />
        </div>
      ))}
  </>
)

export default EventsListPlaceholder
