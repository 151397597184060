import classnames from 'classnames'
import { FC, ImgHTMLAttributes } from 'react'

import { useImage } from '~hooks'

import styles from './Image.module.scss'

const placeholderBase64 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
// transparent 1x1 png

type ImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  rounded?: boolean
  className?: string
  wrapperClassName?: string
  showPlaceholder?: boolean
  aspectRatio?: 'ar-4-3' | 'ar-16-9'
}

const Image: FC<ImageProps> = ({
  rounded,
  showPlaceholder = true,
  className,
  aspectRatio,
  wrapperClassName,
  ...props
}) => {
  const { loading, ref, src } = useImage({ src: props.src })

  return (
    <div
      className={classnames(
        styles.wrapper,
        wrapperClassName,
        { [styles.rounded]: rounded },
        styles[aspectRatio]
      )}
      style={{ opacity: loading && showPlaceholder ? '0.7' : '1' }}
    >
      {loading && showPlaceholder && (
        <div className={classnames(styles.absolute, styles.placeholder)}>
          <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" fill="black" />
            <g filter="url(#filter0_dddd_1786_108)">
              <path
                d="M44.5 43L39 36L33.6081 29.1376C31.4077 26.3371 30.3075 24.9369 30.8178 24.316C31.3281 23.6952 32.9149 24.5034 36.0885 26.1198L59.4826 38.0355C60.6742 38.6424 61.27 38.9459 61.3359 39.4721C61.4017 39.9983 60.8991 40.4393 59.8937 41.3211L40.1063 58.6789C39.1009 59.5607 38.5983 60.0017 38.6641 60.5279C38.73 61.0541 39.3258 61.3576 40.5174 61.9645L63.9115 73.8802C67.0851 75.4966 68.6719 76.3048 69.1822 75.684C69.6925 75.0631 68.5923 73.6629 66.3919 70.8624L61 64L55.5 57"
                stroke="#179917"
                strokeLinecap="round"
              />
            </g>
            <g filter="url(#filter1_f_1786_108)">
              <path
                d="M44.5 43L39 36L33.6081 29.1376C31.4077 26.3371 30.3075 24.9369 30.8178 24.316C31.3281 23.6952 32.9149 24.5034 36.0885 26.1198L59.4826 38.0355C60.6742 38.6424 61.27 38.9459 61.3359 39.4721C61.4017 39.9983 60.8991 40.4393 59.8937 41.3211L40.1063 58.6789C39.1009 59.5607 38.5983 60.0017 38.6641 60.5279C38.73 61.0541 39.3258 61.3576 40.5174 61.9645L63.9115 73.8802C67.0851 75.4966 68.6719 76.3048 69.1822 75.684C69.6925 75.0631 68.5923 73.6629 66.3919 70.8624L61 64L55.5 57"
                stroke="white"
                strokeWidth="0.9"
                strokeLinecap="round"
              />
            </g>
            <defs>
              <filter
                id="filter0_dddd_1786_108"
                x="10.2691"
                y="4.0001"
                width="79.4618"
                height="92.6374"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="0.56962" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.45098 0 0 0 0 1 0 0 0 0 0.45098 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_1786_108"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="3.98513" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.45098 0 0 0 0 1 0 0 0 0 0.45098 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect1_dropShadow_1786_108"
                  result="effect2_dropShadow_1786_108"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="7.97026" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.45098 0 0 0 0 1 0 0 0 0 0.45098 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect2_dropShadow_1786_108"
                  result="effect3_dropShadow_1786_108"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="0.31881" />
                <feGaussianBlur stdDeviation="9.96282" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.45098 0 0 0 0 1 0 0 0 0 0.45098 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect3_dropShadow_1786_108"
                  result="effect4_dropShadow_1786_108"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect4_dropShadow_1786_108"
                  result="shape"
                />
              </filter>
              <filter
                id="filter1_f_1786_108"
                x="30.0853"
                y="23.4976"
                width="39.8293"
                height="53.0048"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="0.0797026" result="effect1_foregroundBlur_1786_108" />
              </filter>
            </defs>
          </svg>
        </div>
      )}

      <img
        ref={ref}
        alt={props.alt}
        className={classnames(styles.image, className, { [styles.rounded]: rounded })}
        style={{
          opacity: +!loading,
        }}
        {...props}
        src={loading ? placeholderBase64 : src}
      />
    </div>
  )
}

export default Image
