import classnames from 'classnames'
import { FC, ReactNode } from 'react'

import { Button, Flex, NoData } from '~ui'
import { IconTypes } from '~ui/Icon'
import { useScreenClass } from '~hooks'
import Placeholder from './placeholder'
import ItemContainer, { ItemProps } from './Item'
import WidgetItem from './WidgetItem'

import styles from './ItemsList.module.scss'

export interface ItemsListProps {
  items: ItemProps[]
  noDataText?: ReactNode | string
  loading: boolean
  displayNoData?: boolean
  showLoadMoreButton?: boolean
  placeholderItemsCount?: number
  onLoadMore?: () => void
  iconForMobile?: IconTypes
  widget?: boolean
}

const ItemsList: FC<ItemsListProps> = ({
  items,
  loading,
  onLoadMore,
  iconForMobile,
  showLoadMoreButton,
  displayNoData = true,
  placeholderItemsCount = 10,
  noDataText = 'No events available at this time.',
  widget,
}) => {
  const { isMobile } = useScreenClass()

  if (!items?.length && loading) {
    return <Placeholder itemsCount={placeholderItemsCount} />
  }

  if (!items?.length && !loading) {
    return displayNoData ? (
      <div>{typeof noDataText === 'string' ? <NoData description={noDataText} /> : noDataText}</div>
    ) : null
  }

  return (
    <div
      className={classnames(styles.items, {
        [styles.transparentBackground]: isMobile,
        [styles['no-padding-bottom']]: !showLoadMoreButton,
      })}
    >
      {isMobile && <div className={styles.divider} />}
      {items.map(
        ({
          fields,
          imageSrc,
          link,
          eventSlug,
          date,
          actions,
          onlyVip,
          external,
          isEvent,
          sellingFast,
        }) =>
          widget ? (
            <WidgetItem
              key={`${imageSrc}-${link}`}
              fields={fields}
              imageSrc={imageSrc}
              link={link}
              date={date}
              onlyVip={onlyVip}
              eventSlug={eventSlug}
              actions={actions}
              isMobile={isMobile}
              external={external}
              iconForMobile={iconForMobile}
              isEvent={isEvent}
              sellingFast={sellingFast}
            />
          ) : (
            <ItemContainer
              key={`${imageSrc}-${link}`}
              fields={fields}
              imageSrc={imageSrc}
              link={link}
              date={date}
              onlyVip={onlyVip}
              eventSlug={eventSlug}
              actions={actions}
              isMobile={isMobile}
              external={external}
              iconForMobile={iconForMobile}
              isEvent={isEvent}
              sellingFast={sellingFast}
            />
          )
      )}
      {showLoadMoreButton && onLoadMore && (
        <Flex justifyContent="center">
          <Button variant="secondary" onClick={onLoadMore} loading={loading}>
            Load more
          </Button>
        </Flex>
      )}
    </div>
  )
}

export default ItemsList
