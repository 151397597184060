import { FC } from 'react'

import { TypographyProps } from 'components/ui/Typography'
import { Typography, Flex } from '~ui'

const { Text } = Typography

export type Fields = Array<
  { text: string; gray?: boolean; color?: GThemeColorsNew } & TypographyProps
>

const TextCol: FC<{ fields: Fields }> = ({ fields }) => (
  <Flex flexDirection="column" overflow="hidden">
    {fields.map(({ text, bold, gray, size, lineClamp, ellipsis = true, color = 'white' }) => (
      <Text
        key={text}
        bold={bold}
        size={size}
        color={(gray && 'neutral') || color}
        ellipsis={ellipsis}
        lineClamp={lineClamp}
      >
        {text}
      </Text>
    ))}
  </Flex>
)

export default TextCol
