import { useEffect, useRef, useState } from 'react'

import { imagePreloader } from '~utils'

type ImageCache = {
  promise: Promise<unknown>
  status: 'pending' | 'error' | 'success'
  error?: unknown
  src?: string
}

type UseImageParams = IntersectionObserverInit & {
  src?: string
}

const cache: Map<string, ImageCache> = new Map<string, ImageCache>()

const useImage = ({ src, ...params }: UseImageParams) => {
  const ref = useRef<HTMLImageElement>()

  const [loading, setLoading] = useState(!cache.has(src))

  useEffect(() => {
    if (!ref.current && cache.has(src)) return
    if (!IntersectionObserver) {
      setLoading(false)
      return
    }

    const observer = new IntersectionObserver(
      (entries, o) => {
        entries.forEach(({ target, isIntersecting }) => {
          if (isIntersecting) {
            if (!cache.has(src)) {
              cache.set(src, { promise: imagePreloader(src), status: 'pending' })
              o.unobserve(target)
              setLoading(true)
            }

            cache
              .get(src)
              .promise.then(() => {
                cache.set(src, { ...cache.get(src), status: 'success', src })
                setLoading(false)
              })
              .catch((error) => {
                cache.set(src, { ...cache.get(src), status: 'error', error })
                setLoading(false)
              })
          }
        })
      },
      { ...params }
    )

    observer.observe(ref.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src])

  return { loading, src, ref, info: cache.get(src) }
}

export default useImage
